<template>
  <div>
    <!-- <b-row>
      <b-col lg="8">
        <b-row class="match-height">
          <b-col cols="12">
            <card-analytic-sale-line-chart />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4">
        <card-analytic-product-orders />
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
  import { BRow, BCol } from 'bootstrap-vue'
  import CardAnalyticProductOrders from './CardAnalyticProductOrders.vue'
  import CardAnalyticSaleLineChart from './CardAnalyticSaleLineChart.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      BRow,
      BCol,
      CardAnalyticProductOrders,
      CardAnalyticSaleLineChart,
    },
    data() {
      return {
        totalRows: 1,
        totalCons: 1,
        totalPend: 1,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 25,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
